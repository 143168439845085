import Options from './options';

export default function IntroductionOptions(properties) {

    const options = [
        {
            post_title: 'Aardappel Salade',
            ID: 236852
        },
        {
            post_title: 'Aardappelsoep',
            ID: 236925
        },
        {
            post_title: 'Aardbeien',
            ID: 237016
        },
        {
            post_title: 'Abrikozen',
            ID: 236618
        },
    ];

    const newProperties = { ...properties, payload: { options } };

    return <Options {...newProperties} />

}