import { useEffect, useState } from 'react';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css';
import './css/react-chatbot-kit-override.css';

import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';

import { getAutoCompleteList } from './data/data';
import AutoComplete from './components/autoComplete';

export default function App() {

    const [showBot, toggleBot] = useState(true);

    const [autoCompelteListFull, setAutoCompleteListFull] = useState([]);
    const [filteredAutoCompleteList, setFilteredAutoCompleteList] = useState([]);

    useEffect(() => {

        const unloadCallback = (event) => {
            toggleBot(false);
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    // retrieve all meals beforehand, used for building autocomplete list
    useEffect(() => {

        const retrieveAutoCompleteList = async () => {

            const autoCompleteItems = await getAutoCompleteList();

            if (autoCompleteItems?.message === "Successful" && autoCompleteItems?.meals?.length > 0) {
                setAutoCompleteListFull(autoCompleteItems.meals);
            }

        }

        retrieveAutoCompleteList();

    }, []);

    const saveMessages = (messages, HTMLString) => {
        localStorage.setItem('chat_messages', JSON.stringify(messages));
    };

    const loadMessages = () => {
        const chat_messages = localStorage.getItem('chat_messages') || false;
        const messages = chat_messages ? JSON.parse(localStorage.getItem('chat_messages')) : "";
        return messages;
    };

    const buildAutoComplete = (e) => {
        let value = e.target.value;

        if (value.length >= 3) {

            var validRegex = true;

            try {
                new RegExp(value);
            } catch (e) {
                validRegex = false;
            }

            if (validRegex) {

                const regex = new RegExp(value.toLowerCase());

                let filteredAutoComplete = autoCompelteListFull.filter((item, index) => {

                    if (regex.test(item.post_title.toLowerCase())) {
                        return item;
                    }

                    return false;

                })

                setFilteredAutoCompleteList(filteredAutoComplete);
            }

        } else {

            setFilteredAutoCompleteList([]);

        }

    }

    useEffect(() => {

        const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];

        if (input) {
            input.addEventListener("keyup", (e) => {
                buildAutoComplete(e, autoCompelteListFull);
            });
        }

    }, [autoCompelteListFull])

    const handleSearchTerm = (term) => {

        const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
        const button = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

        // Dirty hack to change input and state
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(input, term);

        var inputEvent = new Event('input', { bubbles: true });
        input.dispatchEvent(inputEvent);

        setTimeout(() => {

            button.click();
            empty();

        }, 500);

    }

    const empty = () => {
        setFilteredAutoCompleteList([]);
    }

    return (
        <div className="chatbot">
            <header className="appHeader" />
            {showBot &&
                <Chatbot
                    config={config}
                    headerText='Sam Melier'
                    actionProvider={ActionProvider}
                    messageParser={MessageParser}
                    placeholderText='Wat ga je eten?'
                    messageHistory={loadMessages()}
                    saveMessages={saveMessages}
                />
            }
            <AutoComplete list={filteredAutoCompleteList} handleSearchTerm={handleSearchTerm} />
        </div>
    );
}