import React from 'react';
import { getMealData } from './data/data';

export default function ActionProvider({ createChatBotMessage, setState, children }) {

    const handleHello = () => {
        const botMessage = createChatBotMessage('Hallo daar, hoe kan ik je helpen?');

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleSearch = async (searchTerm, searchTitle = "") => {

        const mealData = await getMealData(searchTerm);

        if (mealData?.type?.error) {

            const botMessage = createChatBotMessage(mealData.message);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

            return;

        }

        let message = mealData.type == "meals"
            ? `Op jouw gezochte gerecht "${searchTitle ? searchTitle : searchTerm}" zijn de volgende ${mealData?.data?.meals?.length} gerechten gevonden`
            : `Bij "${searchTitle ? searchTitle : searchTerm}" passen de onderstaande wijnen het best.`

        const botMessage = createChatBotMessage(
            message,
            {
                widget: "options",
                loading: true,
                terminateLoading: true,
                payload: {
                    options: mealData.data[mealData.type] || []
                }
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));

    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleHello,
                        handleSearch
                    },
                });
            })}
        </div>
    );
};