import "./introductionBySam.css";

export default function IntroductionBySam(properties) {

    // console.log(properties);

    return (
        <div className="introduction">
            <div>
                <p>Hoi mijn naam is Sam Melier en ik ben hier om jou te helpen met die ene vraag:</p>
                <p>"Wat drinken we er bij?"</p>
            </div>
            <img src="/assets/sam.png" alt="Sam Melier" />
        </div>
    )
}