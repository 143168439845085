import "./autoComplete.css";

export default function AutoComplete(properties) {

    const { list = [], handleSearchTerm } = properties;
    
    if (list.length === 0) return;

    return (
        <div className="autoComplete">
            <ul>
                {list.map((item, index) => {

                    if (index > 4) return false;

                    return (
                        <li key={index}><button onClick={() => handleSearchTerm(item.post_title)}>{item.post_title}</button></li>
                    )

                })}
            </ul>
        </div>
    )
}