import {
    createChatBotMessage,
    createCustomMessage
} from "react-chatbot-kit";

import IntroductionBySam from "./messages/introductionBySam.js";
import Options from './widgets/options/options.js';
import IntroductionOptions from './widgets/options/introductionOptions.js';

const botName = 'Sam Melier';

const config = {
    botName: botName,
    customMessages: {
        introductionBySam: (props) => <IntroductionBySam {...props} />,
    },
    initialMessages: [
        createCustomMessage('Intro', 'introductionBySam'),
        createChatBotMessage(`Wat staat er vanavond bij je op het menu? Een heerlijke Hollandse stampot of misschien wel je favoriete pasta?`),
        // createChatBotMessage(`Staat jouw gerecht tussen te volgende suggesties?`,
        //     {
        //         delay: 500,
        //         widget: 'introductionOptions'
        //     })
    ],
    state: {
        search: {}
    },
    widgets: [
        {
            widgetName: 'options',
            widgetFunc: (properties) => <Options {...properties} />,
        },
        {
            widgetName: 'introductionOptions',
            widgetFunc: (props) => <IntroductionOptions {...props} />,
            props: {}
        },
    ]
}

export default config