import "./options.css";

export default function Options(properties) {


    const { payload, actionProvider } = properties;
    const results = payload?.options || [];

    return (
        <div className="options">
            <div className="options-container">
                {results?.length > 0 && results?.map((option, index) => {
                    
                    if (option.url) {
                        return (
                            <a 
                                className="option-item"
                                href={option.url} 
                                key={index}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {option.name}
                            </a>
                        )
                    }

                    return (
                        <div
                            className="option-item"
                            onClick={() => actionProvider?.handleSearch(option.ID, option.post_title)}
                            key={index}
                        >
                            {option.post_title}
                        </div>
                    );
                })}
                
            </div>
        </div>
    );
};
