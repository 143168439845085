import config from '../constants/config';

export const getAutoCompleteList = async (searchTerm) => {

    let response = await fetch(`${config.API}get/meals`);

    if (!response.ok) {

        return response = {
            message: "error",
            message: "Oeps, er ging iets fout."
        };

    }

    let data = await response.json();

    return data;
}

export const getMealData = async (searchTerm) => {

    let response = await fetch(`${config.API}get/meals/wine_suggestions?meal=${searchTerm}`);

    if (!response.ok) {

        return response = {
            type: "error",
            message: "Oeps, er ging iets fout."
        };

    }

    let data = await response.json();

    if (data?.meals?.length > 0) {
        return data = {
            data: data,
            type: "meals"
        };
    }

    if (data?.wines?.length === 0) {
        return response = {
            type: "error",
            message: "Oeps, jouw zoekopdracht heeft geen resultaten opgeleverd."
        };
    }

    return data = {
        data: data,
        type: "wines"
    };

};