import React from 'react';

export default function MessageParser({ children, actions }) {

    const parse = (message) => {
        
        if (message.includes("hallo")) {
            actions.handleHello();
            return;
        }

        actions.handleSearch(message);

    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions
                });
            })}
        </div>
    );
};